import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;
const StyledPic = styled.div`
  position: relative;
  max-width: 300px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--green);

    &:hover,
    &:focus {
      outline: 0;

      &:after {
        top: 15px;
        left: 15px;
      }

      .img {
        filter: none;
        mix-blend-mode: normal;
      }
    }

    .img {
      position: relative;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: grayscale(100%) contrast(1);
      transition: var(--transition);
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--navy);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid var(--green);
      top: 20px;
      left: 20px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const skills = ['JavaScript (ES6+)', 'TypeScript', 'React', 'Eleventy', 'Node.js', 'WordPress'];

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">Qui suis-je ?</h2>

      <div className="inner">
        <StyledText>
          <div>
            <p>
              Bonjour ! <br/><br/>Je m'appelle Rémy, j'ai 21 ans et je suis étudiant en Informatique.
              J'ai toujours été passionné par ce domaine, en effet dès l'obtention de mon Bac ES en 2019, 
              je me suis orienté vers un DUT Informatique à l'Université Sorbonne Paris Nord (USPN) dans l'optique
              de monter en compétences théoriques et techniques.
              <br/><br/>Suite à cela, j'ai continué mon apprentissage en 1ère année du cycle Ingénieur à CY Tech, puis j'ai décidé de me réorienter dans une formation entièrement dédiée à la cybersécurité à l'ESGI, domaine qui m'as toujours intéréssé.
              
            </p>

           <p>
            Pour étendre mon apprentissage je me suis inscrit sur des
            plateformes spécialisées telles que root-me, HackTheBox ou OZINT que je fréquente régulièrement afin de me challenger.
            Dernièrement, j'ai lancé mon application web de vulgarisation informatique <a href="https://wiki.remydionisio.fr" target="_blank" rel="noreferrer">Gotham</a>
           </p>

            <p>Voici quelques technologies et outils avec lesquels j'ai pu travailler récemment :</p>
          </div>

          <ul className="skills-list">
            <li>Selenium Python (Web Scraping)</li>
            <li>Administration Active Directory</li>
            <li>Framework Gophish</li>
            <li>Windows Server 2019</li>
            <li>Cloud Computing (automatisation Azure et AWS)</li>
            <li>Kali Linux (Burp, Nmap, Sqlmap, testssl, Metasploit...)</li>
          </ul>
        </StyledText>

        <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/me.jpg"
              width={500}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>
        </StyledPic>
      </div>
    </StyledAboutSection>
  );
};

export default About;